import "./App.css";

import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

function App() {
  return (
    <div className="App">
      <div className="main-wrapper main-wrapper-responsive-lg">
        <main className="main-container container-fluid">
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/privacypolicy"} element={<PrivacyPolicy />} />
            <Route path={"/termsofuse"} element={<TermsOfUse />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
