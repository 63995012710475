import React from "react";
import { Row } from "react-bootstrap";

const TermsOfUse = () => {
  return (
    <div style={styles.container}>
      <Row>
        <img
          src={require("../assets/images/logo.webp")}
          style={styles.logo}
          alt={""}
        />
      </Row>
      <Row>
        <img src={require("../assets/images/terms1.webp")} alt={""} />
      </Row>
      <Row>
        <img src={require("../assets/images/terms2.webp")} alt={""} />
      </Row>
      <Row>
        <img src={require("../assets/images/terms3.webp")} alt={""} />
      </Row>
    </div>
  );
};

export default TermsOfUse;

const styles = {
  container: {
    backgroundColor: "black",
  },
  logo: {
    width: 400,
    height: "auto",
  },
};