import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div style={styles.container}>
      <div style={styles.contentContainer}>
        <Row>
          <Col>
            <Row>
              <img
                src={require("../assets/images/logo.webp")}
                style={styles.logo}
                alt={"logo"}
              />
            </Row>
            <Row style={styles.linkContainer}>
              <Link to={"/privacypolicy"}>
                <span style={styles.link}>Privacy Policy</span>
              </Link>
              <Link to={"/termsofuse"}>
                <span style={styles.link}>Terms of Use</span>
              </Link>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;

const styles = {
  container: {
    backgroundColor: "black",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  },
  link: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    padding: 20
  },
  linkContainer: {
    display:"flex",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
};
