import React from "react";
import { Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <Row>
        <img
          src={require("../assets/images/logo.webp")}
          style={styles.logo}
          alt={""}
        />
      </Row>
      <Row>
        <img
          src={require("../assets/images/pp1.webp")}
          // style={styles.eventImage}
          alt={""}
        />
      </Row>
      <Row>
        <img
          src={require("../assets/images/pp2.webp")}
          // style={styles.eventImage}
          alt={""}
        />
      </Row>
      <Row>
        <img
          src={require("../assets/images/pp3.webp")}
          // style={styles.eventImage}
          alt={""}
        />
      </Row>
      <Row>
        <img
          src={require("../assets/images/pp4.webp")}
          // style={styles.eventImage}
          alt={""}
        />
      </Row>
    </div>
  );
};

export default PrivacyPolicy;

const styles = {
  container: {
    backgroundColor: "black",
  },
  logo: {
    width: 400,
    height: "auto",
  },
};
